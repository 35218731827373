

































































































































































.checkListData_1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
}
.checkListData_2 {
  line-height: 30px;
  .checkListData_2_1 {
    padding: 0 5px;
  }
  .checkListData_2_2 {
    padding: 0 5px;
    margin-right: 5px;
  }
}
